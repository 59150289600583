
import { Vue } from "vue-class-component";
import { ApiStateData } from "@/domain/entities/Api";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { MiddlewareList, MiddlewareData } from "@/domain/entities/Middleware";
import { MiddlewareController } from "@/app/ui/controllers/MiddlewareController";
import { Pagination } from "@/domain/entities/Pagination";
import { RequestMiddlewareList } from "@/data/payload/api/MiddlewareApiRequest";

export default class MappingToken extends Vue {
  apiListData = new ApiStateData();
  firstRequest = true;
  listData = new MiddlewareList({
    pagination: new Pagination(1, 20),
    data: []
  })

  async fetchData() {
    this.apiListData.loading = true;
    this.apiListData.errorType = "";

    await MiddlewareController.getListMiddleware({
      type: "mapping-token",
      queries: new RequestMiddlewareList({
        page: this.listData.pagination.page,
        limit: this.listData.pagination.limit,
        search: this.searchValue
      })
    }).then((data: MiddlewareList) => {
      this.listData.data = data.data;
      this.listData.pagination = data.pagination;
    }).catch((error: any) => {
      this.apiListData.errorType = parsingErrorResponse(error).type;
    }).finally(() => {
      this.apiListData.loading = false;
    });
  }

  mounted() {
    this.fetchData();
  }

  columns = [
    {
      name: "ID",
      key: "id",
      styleHead: "w-1/12 text-center whitespace-no-wrap",
      styleBody: "",
      render: (item: MiddlewareData) => {
        return `<span class="whitespace-no-wrap text-black-lp-300">${item.id}</span>`;
      }
    },
    {
      name: "Nama Pengguna",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: MiddlewareData) => {
        return `<span class="whitespace-no-wrap break-all text-black-lp-300">${item.userName}</span>`;
      }
    },
    {
      name: "Kode Klien Parent",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: MiddlewareData) => {
        return `<span class="whitespace-no-wrap text-black-lp-300">${item.clientParentCode}</span>`;
      }
    },
    {
      name: "Nomor Telepon Klien",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: MiddlewareData) => {
        return `<span class="whitespace-no-wrap text-black-lp-300">${item.clientPhone}</span>`;
      }
    },
    {
      name: "Token",
      styleHead: "w-4/20 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: MiddlewareData) => {
        return `<span class="whitespace-no-wrap break-all text-black-lp-300">${item.token}</span>`;
      }
    },
  ];

  createMappingToken() {
    this.$router.push("/admin/middleware/mapping-token/add");
  }

  onClickRow(item: MiddlewareData) {
    this.$router.push(
      `/admin/middleware/mapping-token/${item.id}?params=${item.userName}`
    );
  }
  
  // search
  searchValue = ""
  onSearch(value: string) {
    this.firstRequest = false;
    this.listData.pagination = new Pagination(1, 20);
    this.searchValue = value;
    this.fetchData();
  }
  clearSearch() {
    this.onSearch("");
  }
}
