
import { Vue } from "vue-class-component";

export default class Middleware extends Vue {
  // Tabs
  tabs = [
    { name: "mapping-code", title: "Mapping Code" },
    { name: "mapping-token", title: "Mapping Token" }
  ];

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
