
import { Vue, Options } from "vue-class-component";
import { MiddlewareController } from "@/app/ui/controllers/MiddlewareController";
import { Pagination } from "@/domain/entities/Pagination";
import { MiddlewareList, MiddlewareData } from "@/domain/entities/Middleware";
import { RequestMiddlewareList } from "@/data/payload/api/MiddlewareApiRequest";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ApiStateData } from "@/domain/entities/Api";

export default class MappingCode extends Vue {
  mounted() {
    this.fetchData();
  }

  apiListData = new ApiStateData();
  async fetchData() {
    this.apiListData.loading = true;
    this.apiListData.errorType = "";
    await MiddlewareController.getListMiddleware({
      type: "mapping-code",
      queries: new RequestMiddlewareList({
        page: this.listData.pagination.page,
        limit: this.listData.pagination.limit,
        search: this.searchValue
      })
    }).then((data: MiddlewareList) => {
      this.listData.pagination = data.pagination;
      this.listData.data = data.data;
    }).catch((err: any) => {
      this.apiListData.errorType = parsingErrorResponse(err).type;
    }).finally(() => {
      this.apiListData.loading = false;
    })
  }
  
  
  firstRequest = true;
  listData = new MiddlewareList({
    pagination: new Pagination(1, 20),
    data: []
  })
  
  // search
  searchValue = ""
  onSearch(value: string) {
    this.firstRequest = false;
    this.listData.pagination = new Pagination(1, 20);
    this.searchValue = value;
    this.fetchData();
  }
  clearSearch() {
    this.onSearch("");
  }

  columns = [
     {
      name: "ID",
      key: "id",
      styleHead: "w-1/12 text-center whitespace-no-wrap",
      styleBody: "",
      render: (item: MiddlewareData) => {
        return `<span class="whitespace-no-wrap text-black-lp-300">${item.id}</span>`;
      }
    },
    {
      name: "Nama Pengguna",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: MiddlewareData) => {
        return `<span class="whitespace-no-wrap break-all text-black-lp-300">${item.userName}</span>`;
      }
    },
    {
      name: "Nama Klien Branch",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: MiddlewareData) => {
        return `<span class="whitespace-no-wrap text-black-lp-300">${item.clientBranchName}</span>`;
      }
    },
    {
      name: "Kode Eksternal Klien Branch",
      styleHead: "w-4/20 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: MiddlewareData) => {
        return `<span class="whitespace-no-wrap text-black-lp-300">${item.externalCodeClientBranch}</span>`;
      }
    },
    {
      name: "Kode Klien Branch",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: MiddlewareData) => {
        return `<span class="whitespace-no-wrap text-black-lp-300">${item.clientBranchCode}</span>`;
      }
    },
    {
      name: "Kota Klien",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: MiddlewareData) => {
        return `<span class="whitespace-no-wrap text-black-lp-300">${item.clientCity}</span>`;
      }
    },
    {
      name: "Klien Crossdock",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: MiddlewareData) => {
        return `<span class="whitespace-no-wrap text-black-lp-300">${item.clientCrossDock}</span>`;
      }
    },
  ];

  createMappingCode() {
    this.$router.push("/admin/middleware/mapping-code/add");
  }

  onClickRow(item: MiddlewareData) {
    this.$router.push(
      `/admin/middleware/mapping-code/${item.id}?params=${item.userName}`
    );
  }

}
