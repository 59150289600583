import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }
const _hoisted_2 = { class: "flex w-9/12 gap-x-4" }
const _hoisted_3 = {
  key: 1,
  class: "overflow-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      class: "items-center",
      isBorderBottom: false
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Search, {
            value: _ctx.searchValue,
            onInput: _ctx.onSearch,
            class: "w-6/12",
            placeholder: "Cari nama pengguna atau nama klien",
            onClear: _ctx.clearSearch
          }, null, 8, ["value", "onInput", "onClear"])
        ]),
        _createVNode(_component_lp_button, {
          class: "pt-6",
          onClick: _ctx.createMappingCode,
          title: "Buat Mapping Code",
          textColor: "white"
        }, null, 8, ["onClick"])
      ]),
      _: 1
    }),
    (
        _ctx.firstRequest &&
          _ctx.listData.data.length === 0 &&
          !_ctx.apiListData.loading &&
          !_ctx.apiListData.errorType
      )
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          "error-title": "Tidak Ada Mapping Code",
          "error-image": "noData_empty",
          "error-message": "Data Mapping Code tidak tersedia"
        }))
      : (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode(_component_TableV2, {
            onRequest: _ctx.fetchData,
            onTryAgain: _ctx.fetchData,
            pagination: _ctx.listData.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.listData.pagination = $event)),
            columns: _ctx.columns,
            data: _ctx.listData.data,
            class: "my-2",
            paginationStyle: "v3",
            borderTop: false,
            errorCause: _ctx.apiListData.errorType,
            loading: _ctx.apiListData.loading,
            isDetailAble: "",
            onClick: _ctx.onClickRow
          }, null, 8, ["onRequest", "onTryAgain", "pagination", "columns", "data", "errorCause", "loading", "onClick"])
        ]))
  ]))
}